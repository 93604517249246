/*START  DEL NAV */
.Container-Nav {
  background: linear-gradient(600deg, rgb(138, 31, 215), #54bab9);
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-shadow: 0px 3px 15px 1px gray;
  position: fixed;
  width: 100%;
  top: 0px;
}

.OneSetcion-nav {
  display: flex;
  justify-content: space-between;
  text-align: center;
  font-family: "Courier New", Courier, monospace;
  color: aliceblue;
  font-size: large;
  flex-wrap: nowrap;
}

.OneSetcion-nav > article {
  display: flex;
  align-items: center;
  text-align: start;
  margin-bottom: 15px;
  margin-right: 30px;
}

.OneSetcion-nav > article > img {
  margin-right: 15px;
}

.Search-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Search-nav > input {
  width: 39vh;
  outline: none;
  border: none;
  height: 35px;
  border-radius: 20px 0px 0px 20px;
  margin-left: -30px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 15px;
  padding-left: 20px;
}
.Search-nav > button {
  height: 37px;
  background-color: navajowhite;
  border: none;
  border-radius: 0px 20px 0px 0px;
  margin-left: -20px;
}
/*END DEL NAV */

/*Start Liscard*/
.ListCard {
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 150px;
  gap: 20px;
  margin-bottom: 100px;
}
/*End Liscard*/

/*Start  Card*/

.Container-Card {
  background-position: center;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 20px 10px 0px gray;
  border-radius: 20px 0px 20px 0px;
  padding: 10px;
  margin: 0px;
  margin-right: 0px;
  align-items: center;
  width: 150px;
  
}
.Container-Card > img {
  width: 110px;
  height: 93px;
  align-items: center;
  justify-content:center ;
  border-radius: 20px;;
}
.Card-Title{
  inline-size: 150px;
  overflow-wrap: break-word;
}
.Describe-Card {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  gap: 10px;
  font-family: "Courier New", Courier, monospace;
  font-size: 17px;
}

.Describe-Card > button {
  background-color: #76ba99;
  border: none;
  border-radius: 20px 0px 20px 0px;
  color: aliceblue;
  text-shadow: 1px 1px 1px black;
}

/*List Load*/
.ContainerLoad{
  position: absolute;
  left: 25vh;
  right: 25vh;
  top: 25vh;
  bottom: 25vh;
}
.spinner {
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.spinner.center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  -webkit-transform-origin: center -0.2222em;
  -ms-transform-origin: center -0.2222em;
  transform-origin: center -0.2222em;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
  animation-delay: 0.083s;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
  animation-delay: 0.166s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
  animation-delay: 0.249s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
  animation-delay: 0.332s;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
  animation-delay: 0.415s;
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
  animation-delay: 0.498s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
  animation-delay: 0.581s;
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
  animation-delay: 0.664s;
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
  animation-delay: 0.747s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
  animation-delay: 0.83s;
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
  animation-delay: 0.913s;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
  0% {
    background-color: #69717d;
  }

  100% {
    background-color: transparent;
  }
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0.4);
    opacity: 0.5;
  }

  50% {
    transform: scale(0);
    opacity: 0.5;
  }
}

/*End  Card*/

/*Start Footer*/
.Container-Footer{
  background: linear-gradient(to top, rgb(29, 2, 38), #54bab9);
  height: 80px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;  
}

.Container-Footer > div {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 19px;
font-family: 'Courier New', Courier, monospace;
  text-align: center;
  margin-bottom: 27px;
}
/*End Footer*/
